<div class="ps--header-flotante">
  <div class="row no-gutters ps--fondo-disconnect">
    <div class="col-md-12 col-12 d-flex justify-content-end">
      <div *ngIf="!checkPrescComGenerico()"
        class="ps--header-disconnect"
        (click)="routerGoTo('logout')"
        (keypress)="routerGoTo('logout')"
        tabindex="0"
      >
        Desconexión
      </div>
    </div>
  </div>
  <div class="ps--header">
    <div class="row no-gutters ps--header-sup">
      <div class="col-6 logo">
        <img
          alt=""
          *ngIf="actuacion === '19' || actuacion === '16' || actuacion === '99'"
          class="ps--header-main-logo"
          [src]="logo1"
        />
        <img
          alt=""
          *ngIf="actuacion === '20'"
          class="ps--header-main-logo"
          [src]="logo2"
        />
        <div
          *ngIf="actuacion === '21'"
          class="presc-correos align-items-center"
        >
          <h1>Prescriptores CORREOS</h1>
        </div>

        <div *ngIf="checkPrescBancaPrivada()"
          class="presc-correos align-items-center">
          <h1>Prescriptores Banca Privada</h1>
        </div>
        <div *ngIf="checkPrescRentingAuto()"
          class="presc-correos align-items-center">
          <h1>Prescriptores Renting Auto</h1>
        </div>
        <div *ngIf="checkPrescUniversidades()"
          class="presc-correos align-items-center">
          <h1>Prescriptores Universidades</h1>
        </div>
        <div *ngIf="checkPrescDigital()"
          class="presc-correos align-items-center">
          <h1>Prescripciones Digitales</h1>
        </div>
      </div>

      <div class="col-6 user">
        <div class="ps--header-user-container">
          <span class="ps--header-user-username">{{ nombre }}</span>
          <span class="ps--header-user-contract">{{ contrato }}</span>
        </div>
        <div
          *ngIf="actuacion === '19' || actuacion === '20'"
          class="ps--header-personal-area"
        >
          <img alt="" src="assets/images/ps-personal-area.svg" />
          <a
            #anclaAreaPersonal
            id="a-personal"
            (click)="showProfile()"
            (keypress)="showProfile()"
            tabindex="10"
            >Área personal</a
          >
          <div *ngIf="showUserProfile" class="ps--header-personal-area-tooltip">
            <div>
              <div class="ps--personal-area-name">
                <div class="d-block row">
                  <div class="ps--personal-area-circle">
                    <img
                      alt=""
                      class="ps--personal-area-img"
                      src="assets/images/ps-personal-area.svg"
                    />
                  </div>
                  <p class="ps--header-user-username-ap">{{ nombre }}</p>
                  <div class="row ps--header-pos">
                    <p class="ps--header-personal-area-dni">{{ doc }}</p>
                    <div
                      *ngIf="actuacion === '19' || actuacion === '20'"
                      class="row ps--header-editar-guardar"
                    >
                      <a
                        id="editar"
                        class="ps--personal-area-editar"
                        (click)="editProfile()"
                        (keypress)="editProfile()"
                        tabindex="20"
                      >
                        Editar
                      </a>
                      <a
                        *ngIf="editado === true"
                        id="guardar"
                        class="ps--personal-area-editar"
                        (click)="guardarAreaP()"
                        (keypress)="guardarAreaP()"
                        tabindex="30"
                      >
                        Guardar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="actuacion === '19' || actuacion === '20'"
                class="ps--personal-area-data"
              >
                <div>
                  <div>
                    <p>Clave Mapfre</p>
                    <span>{{ claveMapf }}</span>
                  </div>
                  <div>
                    <p>Oficina Mapfre</p>
                    <span>{{ oficinaMapfre }}</span>
                  </div>
                  <div>
                    <p>Oficina Tutora Santander</p>
                    <span> {{ contrato }} </span>
                  </div>
                  <div *ngIf="editado === false">
                    <p>Teléfono</p>
                    <span>{{ telf }}</span>
                  </div>
                  <div *ngIf="editado === true">
                    <form form="ngForm" [formGroup]="areaPersonalForm">
                      <div class="ps--personal-area-input">
                        <label for="telefono-personal">Teléfono</label>
                        <input
                          [ngClass]="{
                            'ps--form-invalid':
                              !inputTextInvalid &&
                              this.areaPersonalForm.controls.telefonoP.errors
                          }"
                          id="telefono-personal"
                          formControlName="telefonoP"
                          type="tel"
                          maxlength="11"
                          minlength="9"
                          pattern="^\d+$"
                          name="telefono-personal"
                          autocomplete="off"
                        />
                      </div>
                    </form>
                  </div>
                  <div>
                    <p>Email</p>
                    <span>{{ email }}</span>
                  </div>
                  <div class="row justify-content-center">
                    <a
                      id="cambiar-pass-apersonal"
                      class="ps--cambiar-pass"
                      (click)="navegarCambiarPassWToken()"
                      (keypress)="navegarCambiarPassWToken()"
                      tabindex="40"
                      >Cambiar contraseña</a
                    >
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="false" class="ps--header-personal-area-tooltip"></div>
          </div>
        </div>
        <div
          *ngIf="actuacion === '16' || actuacion === '99'"
          class="ps--header-personal-area"
        >
          <img alt="" src="assets/images/ps-personal-area.svg" />
          <a
            #anclaAreaPersonal
            id="a-personal"
            (click)="showProfile()"
            (keypress)="showProfile()"
            tabindex="50"
            >Área personal</a
          >

          <div
            *ngIf="showUserProfile"
            (clickOutside)="onClickedOutside($event)"
            class="ps--header-personal-area-tooltip-no-mp"
          >
            <div>
              <div class="ps--personal-area-name">
                <div class="d-block row">
                  <div class="ps--personal-area-circle">
                    <img
                      alt=""
                      class="ps--personal-area-img"
                      src="assets/images/ps-personal-area.svg"
                    />
                  </div>
                  <p class="ps--header-user-username-ap">{{ nombre }}</p>
                  <p class="ps--header-personal-area-dni">{{ doc }}</p>
                  <p
                    *ngIf="actuacion === '16' || actuacion === '99'"
                    id="editar"
                    class="ps--personal-area-editar"
                  ></p>
                </div>
              </div>
              <div
                *ngIf="actuacion === '16' || actuacion === '99'"
                class="ps--personal-area-data"
              >
                <div class="ps--personal-area-div" *ngIf="editado === false">
                  <div>
                    <p>Teléfono</p>
                    <span>{{ telf }}</span>
                  </div>
                  <div>
                    <p>Email</p>
                    <span>{{ email }}</span>
                  </div>
                  <div>
                    <p>Dirección Oficina</p>
                    <span>{{ contrato }}</span>
                  </div>
                  <div>
                    <p>Email Oficina</p>
                    <span>{{ emailOficina }}</span>
                  </div>
                  <div>
                    <p>Teléfono Oficina</p>
                    <span>{{ telefOficina }}</span>
                  </div>
                  <div class="row justify-content-center">
                    <a
                      id="cambiar-pass-apersonal"
                      class="ps--cambiar-pass"
                      (click)="navegarCambiarPassWToken()"
                      (keypress)="navegarCambiarPassWToken()"
                      tabindex="60"
                      >Cambiar contraseña</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="actuacion === '21'" class="ps--header-personal-area">
          <img alt="" src="assets/images/ps-personal-area.svg" />
          <a
            #anclaAreaPersonal
            id="a-personal"
            (click)="showProfile()"
            (keypress)="showProfile()"
            tabindex="10"
            >Área personal</a
          >
          <div
            (clickOutside)="onClickedOutside($event)"
            *ngIf="showUserProfile"
            class="ps--header-personal-area-tooltip-correos"
          >
            <div>
              <div class="ps--personal-area-name">
                <div class="d-block row">
                  <div class="ps--personal-area-circle">
                    <img
                      alt=""
                      class="ps--personal-area-img"
                      src="assets/images/ps-personal-area.svg"
                    />
                  </div>
                  <p class="ps--header-user-username-ap">{{ nombre }}</p>
                  <div class="row ps-personal-area-editar-correos">
                    <a
                      id="editar"
                      (click)="editProfile()"
                      (keypress)="editProfile()"
                      tabindex="20"
                    >
                      Editar
                    </a>
                    <a
                      *ngIf="editado === true"
                      id="guardar"
                      class="ps--personal-area-editar"
                      (click)="guardarAreaP()"
                      (keypress)="guardarAreaP()"
                      tabindex="30"
                    >
                      Guardar
                    </a>
                  </div>
                </div>
              </div>
              <div class="ps--personal-area-data">
                <div>
                  <div>
                    <p>Oficina Correos</p>
                    <span>{{ oficinaMapfre }}</span>
                  </div>
                  <div>
                    <p>Oficina Tutora Santander</p>
                    <span> {{ contrato }} </span>
                  </div>
                  <div *ngIf="editado === false">
                    <p>Teléfono</p>
                    <span>{{ telf }}</span>
                  </div>
                  <div *ngIf="editado === true">
                    <form form="ngForm" [formGroup]="areaPersonalForm">
                      <div class="ps--personal-area-input">
                        <label for="telefono-personal">Teléfono</label>
                        <input
                          [ngClass]="{
                            'ps--form-invalid':
                              !inputTextInvalid &&
                              this.areaPersonalForm.controls.telefonoP.errors
                          }"
                          id="telefono-personal"
                          formControlName="telefonoP"
                          type="tel"
                          maxlength="11"
                          minlength="9"
                          pattern="^\d+$"
                          name="telefono-personal"
                          autocomplete="off"
                        />
                      </div>
                    </form>
                  </div>
                  <div>
                    <p>Email</p>
                    <span>{{ email }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="false" class="ps--header-personal-area-tooltip"></div>
          </div>
        </div>
        <div *ngIf="checkPrescComGenerico()"
          class=""
          (click)="routerGoTo('logout')"
          (keypress)="routerGoTo('logout')"
          tabindex="0"
        >
        <img
        alt=""
        class="ps--header-disconnect-icon"
        src="../../../assets/images/turn-off.svg"
        />
      </div>
      </div>
    </div>
    <div class="ps--header-nav">
      <div class="row ml-1 mr-auto">
        <nav>
          <ul class="d-flex justify-content-start">
            <li
              *ngIf="actuacion !== '21'"
              (click)="routerGoTo('home')"
              (keypress)="routerGoTo('home')"
              tabindex="70"
            >
              Inicio
            </li>
            <li
              *ngIf="entornoActual === 'pre' && (checkPrescBancaPrivada())"
              (click)="routerGoTo('prescDigital')"
              (keypress)="routerGoTo('prescDigital')"
              tabindex="70"
            >
              Prescripción Digital
            </li>
            <li
              *ngIf="(actuacion === '19' || actuacion === '20') && !checkPrescComGenerico() && !checkPrescBancaPrivada() && !checkPrescRentingAuto() && !checkPrescUniversidades()"
              (click)="routerGoTo('seguimiento')"
              (keypress)="routerGoTo('seguimiento')"
              tabindex="80"
            >
              Seguimiento de prescripciones
            </li>
            <li
              *ngIf="(actuacion === '16' || actuacion === '99') && !checkPrescComGenerico() && !checkPrescBancaPrivada() && !checkPrescRentingAuto() && !checkPrescUniversidades() && !checkPrescDigital()"
              (click)="routerGoTo('news')"
              (keypress)="routerGoTo('news')"
              tabindex="90"
            >
              Noticias
            </li>
            <li
              *ngIf="(actuacion === '16' || actuacion === '99') && !checkPrescComGenerico() && !checkPrescBancaPrivada() && !checkPrescRentingAuto() && !checkPrescUniversidades() && !checkPrescDigital()"
              (click)="routerGoTo('doc')"
              (keypress)="routerGoTo('doc')"
              tabindex="100"
            >
              Documentos
            </li>
            <li
              *ngIf="(actuacion === '16' || actuacion === '99') && !checkPrescComGenerico() && !checkPrescBancaPrivada() && !checkPrescRentingAuto() && !checkPrescUniversidades() && !checkPrescDigital()"
              (click)="routerGoTo('factura')"
              (keypress)="routerGoTo('factura')"
              tabindex="110"
            >
              Facturas
            </li>
            <li
              *ngIf="(actuacion === '16' || actuacion === '99') && !checkPrescComGenerico() && !checkPrescBancaPrivada() && !checkPrescRentingAuto() && !checkPrescUniversidades() && !checkPrescDigital()"
              (click)="routerGoTo('circuito')"
              (keypress)="routerGoTo('circuito')"
              tabindex="120"
            >
              Circuito
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
